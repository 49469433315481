@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@media only screen and (min-width: 600px) {
    .machines {
        margin-top: 30px;
        padding: 20px;
        min-width: 410px !important;
    }
}
@media only screen and (max-width: 600px) {
    .machines {
        margin-top: 10px;
        flex-grow: initial;
        width: 100%;
    }
    .machines:last-child {
        margin-bottom: 10px;
    }
    .menubutton{
        right:10px;
        position: absolute!important;
    }
}
.warning{
    font-size:20pt;
}

[class*='font_FillLevel_'] {
    font-weight: bold;
}

.font_FillLevel_100 {
    color: rgb(27,234,113) !important;
}

.font_FillLevel_66 {
    color: rgb(223,241,58) !important;
}

.font_FillLevel_33 {
    color: rgb(255,136,0) !important;
}

.font_FillLevel_0 {
    color: rgb(236,68,9) !important;
}

.font_coffeeBrewing {
    color: rgb(31,197,229) !important;
    font-weight: bold;
}

.font_coffeeReady{
    color: rgb(27,234,113) !important;
    font-weight: bold;
}

.deactivated{
    background-color: #EFEFEF!important;
    color: #444!important;
}

.heading {
    background-color: #EFEFEF!important;
}