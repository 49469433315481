body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'coffee_cups';
    src: url(/static/media/Glyphter.5e2a5797.eot);
    src: url(/static/media/Glyphter.5e2a5797.eot?#iefix) format('embedded-opentype'),
         url(/static/media/Glyphter.22086adb.woff) format('woff'),
         url(/static/media/Glyphter.2f2b9e71.ttf) format('truetype'),
         url(/static/media/Glyphter.51054c6a.svg#Glyphter) format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'coffee_cups';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
.icon-coffee_cup_0:before{content:'A';}
.icon-coffee_cup_33:before{content:'B';}
.icon-coffee_cup_66:before{content:'C';}
.icon-coffee_cup_100:before{content:'D';}
.icon-coffee_machine_0:before{content:'E';}
.icon-coffee_machine_100:before{content:'F';}
[class*='icon-']:before {
    font-size: 90pt;
}

/*  https://color.adobe.com/de/create/color-wheel/?base=2&rule=Custom&selected=3&name=Mein%20Color-Thema&mode=rgb&rgbvalues=0.10579766442460276,0.9176470588235294,0.44313725490196076,0.9145364649949733,0.9450980392156862,0.22741958649183075,1,0.531382638706873,0,0.9254901960784314,0.26666666666666666,0.03529411764705882,0.1213975711575307,0.7744927799050627,0.9&swatchOrder=0,1,2,3,4  */
.coffee_FillLevel_100 {
    color: rgb(27,234,113) !important;
}

.coffee_FillLevel_66 {
    color: rgb(223,241,58) !important;
}

.coffee_FillLevel_33 {
    color: rgb(255,136,0) !important;
}

.coffee_FillLevel_0 {
    color: rgb(236,68,9) !important;
}

.coffee_coffeeBrewing {
    color: rgb(31,197,229) !important;
}

.coffee_coffeeReady{
    color: rgb(27,234,113) !important;
}
[class*='coffee_']{
    padding: 20px 20px 15px 20px;
}
@media only screen and (min-width: 600px) {
    .machines {
        margin-top: 30px;
        padding: 20px;
        min-width: 410px !important;
    }
}
@media only screen and (max-width: 600px) {
    .machines {
        margin-top: 10px;
        -webkit-flex-grow: initial;
                flex-grow: initial;
        width: 100%;
    }
    .machines:last-child {
        margin-bottom: 10px;
    }
    .menubutton{
        right:10px;
        position: absolute!important;
    }
}
.warning{
    font-size:20pt;
}

[class*='font_FillLevel_'] {
    font-weight: bold;
}

.font_FillLevel_100 {
    color: rgb(27,234,113) !important;
}

.font_FillLevel_66 {
    color: rgb(223,241,58) !important;
}

.font_FillLevel_33 {
    color: rgb(255,136,0) !important;
}

.font_FillLevel_0 {
    color: rgb(236,68,9) !important;
}

.font_coffeeBrewing {
    color: rgb(31,197,229) !important;
    font-weight: bold;
}

.font_coffeeReady{
    color: rgb(27,234,113) !important;
    font-weight: bold;
}

.deactivated{
    background-color: #EFEFEF!important;
    color: #444!important;
}

.heading {
    background-color: #EFEFEF!important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

